<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Side Products</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/salesTargets/detail" class="mb-2"
        >Create</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="$store.state.salesTargets.all.data"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.goalPrice="{ item }">
        <div>
          {{ item.goalPrice | formatMoney }}
        </div>
      </template>
      <template v-slot:item.startDate="{ item }">
        <div>{{ new Date(item.startDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.endDate="{ item }">
        <div>{{ new Date(item.endDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import router from "@/router";

export default {
  data() {
    return {
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "endDate", value: "endDate" },
        { text: "startDate", value: "startDate" },
        { text: "quarter", value: "quarter" },
        { text: "goalPrice", value: "goalPrice" },
        { text: "realizedGoalPrice", value: "realizedGoalPrice" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("salesTargets/delete", item.id);
      await this.initialize();
    },
    editItem(item) {
      router.push("/salesTargets/detail/" + item.id);
    },
    async initialize() {
      await this.$store.dispatch("salesTargets/getAll", {});
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
